import React from "react";
import Btn from "../Components/Products/Brand/Btn";
import Transl from "../Components/Transl/Transl";
import BrandTable from "../Components/Products/Brand/BrandTable";

function BrandOfProducts() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <BrandTable />
      </div>
    </div>
  );
}

export default BrandOfProducts;
