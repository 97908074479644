import React from "react";
import Btn from "../Components/Home/SocialLinks/AllSocialLinks/Btn";
import AllSocialLinksTable from "../Components/Home/SocialLinks/AllSocialLinks/SocialLinksTable";

function SocialLinks() {
  return (
    <div>
      <div className="px-10 py-10">
        <AllSocialLinksTable />
      </div>
    </div>
  );
}

export default SocialLinks;
