import React from "react";
import EditPartnerForm from "../Components/Home/Partners/EditPartner/EditPartnerForm";

function EditPartner() {
  return (
    <div className="p-9">
      <EditPartnerForm />
    </div>
  );
}

export default EditPartner;