import React from "react";
import NewCategoryForm from "../Components/Products/NewCategory/NewCategoryForm";

function NewCategoryOfProducts() {
  return (
    <div className="p-9">
      <NewCategoryForm />
    </div>
  );
}

export default NewCategoryOfProducts;
