import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import upload from "../../../Assets/Images/upload.png";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useProducts } from '../../../Context/ProductsContext';

function EditSubBrandForm() {
    const [SelectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(0);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(1);
    const { getProductsSubBrandById, isProductsSubBrandById } = useProducts();

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Products/SubBrands", { replace: true });

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        getProductsSubBrandById(id);
    }, []);

    useEffect(() => {
        if (isProductsSubBrandById?.data?.subbrand?.brand_id) {
            setSelectedBrand(isProductsSubBrandById?.data?.subbrand?.brand_id);
        }
        if (isProductsSubBrandById?.data?.subbrand?.status) {
            setSelectedStatus(isProductsSubBrandById?.data?.subbrand?.status);
        }
    }, [isProductsSubBrandById]);

    const handleSaveClick = async () => {
        try {
            const body = {
                'name': document.getElementById('name').value,
                'status': selectedStatus,
                'image': SelectedImage,
                'catalog': selectedFile,
                'brand_id': selectedBrand,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/products-subbrand/${id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            console.log(response);
            handleGoToMenu();
            // console.log(res);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            {isProductsSubBrandById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div className="flex flex-col gap-5 pr-[50px]">
                        <div
                            className="flex rounded-lg bg-[#F2F4F7]"
                        >
                            <label
                                htmlFor="imageInput"
                                className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
                            >
                                <img
                                    src={isProductsSubBrandById?.data?.subbrand?.image_url != null && SelectedImage == null ? isProductsSubBrandById?.data?.subbrand?.image_url :
                                        isProductsSubBrandById?.data?.subbrand?.image_url != null && SelectedImage != null ? URL.createObjectURL(SelectedImage) : photo}
                                    alt="File Upload"
                                    className={SelectedImage == null && isProductsSubBrandById?.data?.subbrand?.image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}
                                />
                                <input
                                    type="file"
                                    id="imageInput"
                                    className="hidden"
                                    onChange={handleImageChange}
                                />
                            </label>
                        </div>
                        <div
                            className="flex flex-col rounded-lg bg-[#F2F4F7] w-[200px]"
                        >
                            <label
                                htmlFor="fileInput"
                                className="flex flex-row items-center justify-evenly cursor-pointer w-[200px] h-[50px]"
                            >

                                <h3>Upload Catalog</h3>

                                <img
                                    src={upload}
                                    alt="File Upload"
                                    className={"w-[50px] h-[50px] py-2"}

                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                            <h3>
                                {selectedFile == null ?
                                    `${isProductsSubBrandById?.data?.subbrand?.catalog.substring(0, 15)}.` + isProductsSubBrandById?.data?.subbrand?.catalog.split('.').pop() :
                                    selectedFile != null ? `${selectedFile.name.substring(0, 15)}.` + selectedFile.name.split('.').pop() : null
                                }</h3>
                        </div>
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="pl-[50px]" style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Name
                        </label>

                        <input
                            type={'text'}
                            id={'name'}
                            name={'Name'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type name here`}
                            defaultValue={isProductsSubBrandById?.data?.subbrand?.name}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Brand
                        </label>

                        <select
                            name="Brand"
                            id="brand"
                            value={selectedBrand}
                            onChange={(e) => {
                                setSelectedBrand(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            {isProductsSubBrandById?.data?.brands.map((brand, index) => (
                                <option key={brand.id} value={brand.id}>{brand.name}</option>
                            ))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus != 0 ? selectedStatus : isProductsSubBrandById?.data?.status}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>

                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditSubBrandForm