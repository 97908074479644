import React from "react";
import AllCareersTable from "../Components/Careers/AllCareers/AllCareerTable";
import Btn from "../Components/Careers/AllCareers/Btn";

function Careers() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllCareersTable />
      </div>
    </div>
  );
}

export default Careers;
