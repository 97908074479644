import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useHome } from '../../../Context/HomeContext';

function Btn() {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const { getPages } = useHome();
  const { lang } = useParams();
  const navigate = useNavigate();

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    setSelectedLanguage(language);
    navigate(`/Pages/${language}`, { replace: true });
    getPages(language);
  };

  return (
    <div className='w-full flex flex-row items-center justify-between'>
      <select
        name="language"
        id="language"
        value={lang}
        onChange={handleLanguageChange} // Use handleLanguageChange function
        className='w-[150px] border rounded-lg px-2 py-1 pr-2'
      >
        <option value="en">English</option>
        <option value="ar">Arabic</option>
      </select>
    </div>
  )
}

export default Btn