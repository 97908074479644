import React from "react";
import Transl from "../Components/Transl/Transl";
import Btn from "../Components/Services/Category/Btn";
import CategoryTable from "../Components/Services/Category/CategoryTable";

function CategoryOfServices() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        {/* <Transl/> */}
        <Btn />
      </div>
      <div className="px-10">
        <CategoryTable />
      </div>
    </div>
  );
}

export default CategoryOfServices;
