import React from "react";
import EditCounterForm from "../Components/Home/Counters/EditCounter/EditCounterForm";

function EditCounter() {
  return (
    <div className="p-9">
      <EditCounterForm />
    </div>
  );
}

export default EditCounter;