import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from '../../../Context/ServicesContext';

function EditCategoryForm() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const { isServicesCategoryById, getServicesCategoryById } = useServices()

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Services/Categories", { replace: true });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        getServicesCategoryById(id);
    }, []);

    const handleSaveClick = async () => {
        try {
            const body = {
                'lang_id': isServicesCategoryById?.data[0].lang_id,
                'name': document.getElementById('name').value,
                'excerpt': document.getElementById('excerpt').value,
                'description': "description",
                'status': selectedStatus != 0 ? selectedStatus : isServicesCategoryById?.data[0].status,
                'image': selectedFile,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/services-category/${isServicesCategoryById?.data[0].id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            console.log(response.status);
            handleGoToMenu();
            // console.log(res);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            {isServicesCategoryById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div className="flex flex-col gap-5 pr-[50px]">
                        <div
                            className="flex rounded-lg bg-[#F2F4F7]"
                        >
                            <label
                                htmlFor="fileInput"
                                className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
                            >
                                <img
                                    src={isServicesCategoryById?.data[0].image_url != null && selectedFile == null ? isServicesCategoryById?.data[0].image_url :
                                        isServicesCategoryById?.data[0].image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                    alt="File Upload"
                                    className={selectedFile == null && isServicesCategoryById?.data[0].image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}

                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="pl-[50px]" style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Name
                        </label>

                        <input
                            type={'text'}
                            id={'name'}
                            name={'Name'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type name here`}
                            defaultValue={isServicesCategoryById?.data[0].name}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Excerpt
                        </label>

                        <textarea
                            id={'excerpt'}
                            name={'Excerpt'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isServicesCategoryById?.data[0].excerpt}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus != 0 ? selectedStatus : isServicesCategoryById?.data[0].status}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>

                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditCategoryForm