import React from "react";
import EditProjectsCategoryForm from "../Components/Projects/EditCategory/EditProjectsCategoryForm";

function EditProjectsCategory() {
  return (
    <div className="p-9">
      <EditProjectsCategoryForm />
    </div>
  );
}

export default EditProjectsCategory;
