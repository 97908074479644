import React from "react";
import NewBannerForm from "../Components/Home/Banner/NewBanner/NewBannerForm";

function NewBanner() {
  return (
    <div className="p-9">
      <NewBannerForm />
    </div>
  );
}

export default NewBanner;