import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import remove from "../../../Assets/Images/remove.png";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useNews } from '../../../Context/NewsContext';
import { ToastContainer, toast } from 'react-toastify';

function EditPostForm() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const { isPostById, getPostById } = useNews()
    const [inputs, setInputs] = useState([{ content: '', image: null }]); // Initialize with one empty inp ut
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newInputs = [...inputs];
        if (event.target.files) {
            newInputs[index][name] = event.target.files[0]; // Store only the first file
            // Update image URL for the corresponding input
            newInputs[index]['image_url'] = URL.createObjectURL(event.target.files[0]);
        } else {
            newInputs[index][name] = value;
        }
        setInputs(newInputs);
        console.log(inputs);
    };

    const handleAddInput = () => {
        setInputs([...inputs, { content: '', image: null }]);
    };

    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        getPostById(id).then((data) => {

        }).catch((error) => {
            console.error('Error fetching Post by ID:', error);
        });
    }, []);

    useEffect(() => {
        if (isPostById?.data?.main_Post?.status) {
            setSelectedStatus(isPostById?.data?.main_Post?.status);
        }
        if (isPostById?.data?.main_Post?.content) {
            setInputs(isPostById.data.main_Post.content);
        }
    }, [isPostById]);

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const body = {
                'lang_id': isPostById?.data?.main_Post?.lang_id,
                'title': document.getElementById('title').value,
                'excerpt': document.getElementById('excerpt').value,
                'date': document.getElementById('date').value,
                'news_cat_id': "1",
                'status': selectedStatus,
                'image': selectedFile,
            };

            if (inputs.length > 0) {
                if (inputs[0].content !== '' && inputs[0].image !== null) {
                    body['contents'] = inputs;
                }
            }

            const response = await axios.post(`${newBaseUrl}/api/update/post/${id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            console.log(response.status);
            toast.success('Post updated successfully!', {
                onClose: () => {
                    navigate(`/News/AllNews/${isPostById?.data?.main_Post.lang_id === 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });
        } catch (err) {
            toast.error('Error updating post. Please try again.');
            console.error(err);
            setLoading(false);
        }

    };

    return (
        <div>
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {loading && <LoadingPopup />}
            <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[50px]">
                <div className="flex flex-col gap-5 pr-[50px]">
                    <div className="flex rounded-lg bg-[#F2F4F7]">
                        <label
                            htmlFor="fileInput"
                            className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]">
                            <img
                                src={isPostById?.data?.main_Post?.image_url != null && selectedFile == null ? isPostById?.data?.main_Post?.image_url :
                                    isPostById?.data?.main_Post?.image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                alt="File Upload"
                                className={selectedFile == null && isPostById?.data?.main_Post?.image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}
                            />
                            <input
                                type="file"
                                id="fileInput"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>
                    <div>
                        <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                            onClick={handleSaveClick}
                            disabled={loading}
                        >
                            {loading ? 'Loading...' : 'Save'}
                        </button>
                    </div>
                </div>
                <div className="scrollable-content pl-[50px]" style={{ width: '100%' }}>
                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Name
                    </label>

                    <input
                        type={'text'}
                        id={'title'}
                        name={'Title'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type title here`}
                        defaultValue={isPostById?.data?.main_Post?.title}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    {/* <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Category
                        </label>

                        <select
                            name="category"
                            id="category"
                            value={selectedCategory != 0 ? selectedCategory : isPostById?.data?.main_Post?.category?.id}
                            onChange={(e) => {
                                setSelectedCategory(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            {isPostById?.data?.NewsCategories.map((Post, index) => (
                                <option key={Post.id} value={Post.id}>{Post.name}</option>
                            ))}
                        </select> */}

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Excerpt
                    </label>

                    <textarea
                        id={'excerpt'}
                        name={'Excerpt'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type excerpt here`}
                        defaultValue={isPostById?.data?.main_Post?.excerpt}
                        style={{
                            height: "80px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Date
                    </label>

                    <input
                        type={'date'}
                        id={'date'}
                        name={'Date'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type excerpt here`}
                        defaultValue={isPostById?.data?.main_Post?.date}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Status
                    </label>

                    <select
                        name="Status"
                        id="status"
                        value={selectedStatus != 0 ? selectedStatus : isPostById?.data?.main_Post?.status}
                        onChange={(e) => {
                            setSelectedStatus(e.target.value);
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value="1">Publish</option>
                        <option value="2">Draft</option>
                    </select>

                    <div className='flex flex-row items-center justify-start pt-2 pb-2'>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1 pr-2"
                        >
                            Content
                        </label>
                        <a
                            className="rounded-lg bg-[#148342] text-[#fff] w-[30px] h-[30px] text-[25px] flex items-center justify-center cursor-pointer"
                            onClick={handleAddInput}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 transform">
                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </div>

                    <div>
                        {inputs.map((input, index) => (
                            <div key={index} className="flex flex-row items-center justify-center gap-5">
                                <textarea
                                    name="content"
                                    defaultValue={input?.content}
                                    onChange={(event) => handleInputChange(index, event)}
                                    className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                                    placeholder={`Type excerpt here`}
                                    style={{
                                        height: "150px",
                                    }}
                                />
                                <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]">
                                    <label className="flex items-center justify-center cursor-pointer w-[150px] h-[150px]">
                                        <img
                                            src={input.image == null ? photo : input?.image_url != null ? input?.image_url : input?.image_url != null && input.image != null ? URL.createObjectURL(input.image) : null}
                                            alt="File Upload"
                                            className={input.image == null ? "w-[75px] h-[75px]" : "rounded-lg w-[150px] h-[150px] lg:w-[150px] lg:h-[150px]"}
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="image"
                                            className="hidden"
                                            onChange={(event) => handleInputChange(index, event)}
                                        />
                                    </label>
                                </div>

                                {<button className="rounded-lg bg-[#AF1F24] text-[#fff] w-[50px] h-[50px] text-[25px] flex items-center justify-center cursor-pointer"
                                    onClick={() => handleRemoveInput(index)} >-</button>}
                            </div>

                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPostForm

const LoadingPopup = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <p className="text-xl font-semibold">Loading...</p>
        </div>
    </div>
);