import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideBar from "../Components/SideBar/SideBar";
import Header from "../Components/Header/Header";

function Layout() {
  const location = useLocation();
  
  const getPageTitle = () => {
    // Extract the second part from the pathname
    const parts = location.pathname.split('/');
    // Check if the parts array has at least two elements
    // Return the second element if it exists, otherwise return an empty string
    return parts.length >= 2 ? parts[1] : '';
  };
  
  const getPageTitle2 = () => {
    // Extract the third part from the pathname
    const parts = location.pathname.split('/');
    // Check if the parts array has at least three elements
    // Return the third element if it exists, otherwise return an empty string
    return parts.length >= 4 ? " - " + parts[2].split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '): 
    parts.length == 3 ? "" : "";
  };
  

  return (
    <div className="flex">
      <SideBar />
      <div className="w-full relative z-10">
        <div className="py-3 shadow px-8 bg-[#fff]">
          {/* Pass the page title to the Header component */}
          <Header pageTitle={`${getPageTitle()}${getPageTitle2()}`} />
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
