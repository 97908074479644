import React from "react";
import NewCareerForm from "../Components/Careers/NewCareer/NewCareerForm";

function NewCareer() {
  return (
    <div className="p-9">
      <NewCareerForm />
    </div>
  );
}

export default NewCareer;