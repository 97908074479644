import React from "react";

import NewSubBrandForm from "../Components/Products/NewSubBrand/NewSubBrandForm";

function NewSubBrandOfProducts() {
  return (
    <div className="p-9">
      <NewSubBrandForm />
    </div>
  );
}

export default NewSubBrandOfProducts;
