import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAbout } from '../../../Context/AboutContext';

function Btn() {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const { getAbout } = useAbout();
  const navigate = useNavigate();
  const { lang } = useParams();

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    setSelectedLanguage(language);
    navigate(`/About/AllAbout/${language}`, { replace: true });
    getAbout(language);
  };

  return (
    <div className='w-full flex flex-row items-center justify-between'>
      <select
        name="language"
        id="language"
        value={lang}
        onChange={handleLanguageChange} // Use handleLanguageChange function
        className='w-[150px] border rounded-lg px-2 py-1 pr-2'
      >
        <option value="en">English</option>
        <option value="ar">Arabic</option>
      </select>
    </div>

  )
}

export default Btn