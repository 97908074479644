import React from "react";
import EditCareerForm from "../Components/Careers/EditCareer/EditCareerForm";

function EditCareer() {
  return (
    <div className="p-9">
      <EditCareerForm />
    </div>
  );
}

export default EditCareer;
