import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"
import NewCategoryForm from "../Components/Projects/NewCategory/NewCategoryForm";

export const ProjectsContext = createContext();
export const useProjects = () => {
    return useContext(ProjectsContext);
};

export default function ProjectsContextProvider({ children }) {

    const [isProjects, setIsProjects] = useState()
    const [isProjectById, setIsProjectById] = useState()
    const [isProjectsCategory, setIsProjectsCategory] = useState()
    const [isProjectsCategoryById, setIsProjectsCategoryById] = useState()

    async function getProjects(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/projects`, { headers });
            setIsProjects(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProjectById(id) {
        setIsProjectById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/project/${id}`, { headers });
            setIsProjectById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteProject(id, lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/project/${id}`, { headers });
            // setIsProjects(res?.data);
            // console.log(res?.data?.data);
            getProjects(lang)
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteProjectGalleryImage(id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/project-gallery/${id}`, { headers });
            // setIsProjects(res?.data);
            // console.log(res?.data?.data);
            // getProjects()
        } catch (err) {
            console.error(err);
        }
    }

    async function getProjectsCategory(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/projects-category`, { headers });
            setIsProjectsCategory(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProjectsCategoryById(id) {
        setIsProjectsCategoryById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/projects-category/${id}`, { headers });
            setIsProjectsCategoryById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteProjectsCategory(id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/projects-category/${id}`, { headers });
            // setIsProjects(res?.data);
            // console.log(res?.data?.data);
            getProjectsCategory()
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <ProjectsContext.Provider value={{
            getProjectsCategory, getProjects, deleteProjectsCategory, getProjectsCategoryById, getProjectById, deleteProject, deleteProjectGalleryImage,
            isProjectsCategoryById, isProjectsCategory, isProjects, isProjectById
        }}>
            {children}
        </ProjectsContext.Provider>
    );
}
