import React from "react";
import Btn from "../Components/Products/Category/Btn";
import Transl from "../Components/Transl/Transl";
import CategoryTable from "../Components/Products/Category/CategoryTable";

function CategoryOfProducts() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <CategoryTable />
      </div>
    </div>
  );
}

export default CategoryOfProducts;
