import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useProjects } from '../../../Context/ProjectsContext';

function EditProjectsCategoryForm() {
    const [selectedStatus, setSelectedStatus] = useState(0);
    const { isProjectsCategoryById, getProjectsCategoryById } = useProjects()

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Projects/Categories", { replace: true });

    useEffect(() => {
        getProjectsCategoryById(id);
    }, []);

    const handleSaveClick = async () => {
        try {
            const body = {
                'lang_id': isProjectsCategoryById?.data[0].lang_id,
                'name': document.getElementById('name').value,
                'excerpt': document.getElementById('excerpt').value,
                'status': selectedStatus != 0 ? selectedStatus : isProjectsCategoryById?.data[0].status,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/projects-category/${isProjectsCategoryById?.data[0].id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            console.log(response.status);
            handleGoToMenu();
            // console.log(res);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            {isProjectsCategoryById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Name
                        </label>

                        <input
                            type={'text'}
                            id={'name'}
                            name={'Name'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type name here`}
                            defaultValue={isProjectsCategoryById?.data[0].name}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Excerpt
                        </label>

                        <textarea
                            id={'excerpt'}
                            name={'Excerpt'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isProjectsCategoryById?.data[0].excerpt}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus != 0 ? selectedStatus : isProjectsCategoryById?.data[0].status}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>
                        <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 mt-[25px] w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditProjectsCategoryForm