import React, { useState } from "react";
import photo from "../../../Assets/Images/Vector.svg";
import add from "../../../Assets/Images/add.png";
import remove from "../../../Assets/Images/remove.png";
import { useNavigate } from "react-router-dom";
import { newBaseUrl } from "../../../Consts";
import axios from "axios";
import { useNews } from "../../../Context/NewsContext";
import { ToastContainer, toast } from "react-toastify";

function NewPostForm() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(1);
    const navigate = useNavigate();
    const { getNewsCategory, isNewsCategory } = useNews();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [inputs, setInputs] = useState([{ text: '', image: null }]); // Initialize with one empty inp ut
    const [loading, setLoading] = useState(false);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newInputs = [...inputs];
        if (event.target.files) {
            newInputs[index][name] = event.target.files[0]; // Store only the first file
        } else {
            newInputs[index][name] = value;
        }
        setInputs(newInputs);
        console.log(inputs);
    };

    const handleAddInput = () => {
        setInputs([...inputs, { text: '', image: null }]);
    };

    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        console.log(inputs[0].text + "   " + inputs[0].image);
    };

    const handleSaveClick = async () => {
        setLoading(true);
            try {
                const body = {
                    'lang_id': selectedLanguage == 0 ? null : selectedLanguage,
                    'title': document.getElementById('title').value,
                    'excerpt': document.getElementById('excerpt').value,
                    'date': document.getElementById('date').value,
                    'news_cat_id': 1,
                    'status': selectedStatus,
                    'image': selectedFile,
                };

                if (inputs[0].text !== '' && inputs[0].image !== null) {
                    body['contents'] = inputs;
                }
                
                const response = await axios.post(`${newBaseUrl}/api/store/news`, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    },
                });

                toast.success('Post added successfully!', {
                    onClose: () => {
                        navigate(`/News/AllNews/${selectedLanguage == 1 ? "en" : "ar"}`, { replace: true });
                        setLoading(false);
                    },
                });

            } catch (err) {
                console.error(err);
                toast.error('Error saving post. Please try again.');
                setLoading(false);          
            }
    };

    return (
        <div>
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {loading && <LoadingPopup />}
            <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                <div className="flex flex-col gap-5 pr-[50px]">
                    <div
                        className="flex rounded-lg bg-[#F2F4F7]"
                    >
                        <label
                            htmlFor="fileInput"
                            className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
                        >
                            <img
                                src={selectedFile == null ? photo : URL.createObjectURL(selectedFile)}
                                alt="File Upload"
                                className={selectedFile == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}

                            />
                            <input
                                type="file"
                                id="fileInput"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>

                    <div>
                        <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                            onClick={handleSaveClick}
                        >
                            Save
                        </button>
                    </div>
                </div>
                <div className="scrollable-content pl-[50px]" style={{ width: '100%' }}>
                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Language
                    </label>
                    <select
                        name="Language"
                        id="language"
                        value={selectedLanguage}
                        onChange={(e) => {
                            setSelectedLanguage(e.target.value);
                            getNewsCategory(e.target.value === '1' ? 'en' : 'ar'); // Corrected condition
                            console.log(e.target.value); // Log the current value from the event
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value='0'>Select Language</option>
                        <option value="1">English</option>
                        <option value="2">Arabic</option>
                    </select>

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Title
                    </label>

                    <input
                        type={'text'}
                        id={'title'}
                        name={'Title'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type title here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Date
                    </label>

                    <input
                        type={'date'}
                        id={'date'}
                        name={'Date'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type title here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    {/* <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Category
                    </label>

                    <select
                        name="category"
                        id="category"
                        value={selectedCategory}
                        onChange={(e) => {
                            setSelectedCategory(e.target.value);
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value='0'>Select Category</option>
                        {isNewsCategory?.data?.map((Post, index) => (
                            <option key={Post.id} value={Post.id}>{Post.name}</option>
                        ))}
                    </select> */}

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Excerpt
                    </label>

                    <input
                        type={'text'}
                        id={'excerpt'}
                        name={'Excerpt'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type excerpt here`}
                        style={{
                            height: "80px",
                            paddingBottom: "3rem",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Status
                    </label>

                    <select
                        name="Status"
                        id="status"
                        value={selectedStatus}
                        onChange={(e) => {
                            setSelectedStatus(e.target.value);
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value="1">Publish</option>
                        <option value="2">Draft</option>
                    </select>

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Description
                    </label>
                    <div>
                        {inputs.map((input, index) => (
                            <div key={index} className="flex flex-row items-center justify-center gap-5">
                                <input
                                    type={'text'}
                                    name="text"
                                    value={input.text}
                                    onChange={(event) => handleInputChange(index, event)}
                                    className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                                    placeholder={`Type excerpt here`}
                                    style={{
                                        height: "150px",
                                        paddingBottom: "7rem",
                                    }}
                                />
                                <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]">
                                    <label className="flex items-center justify-center cursor-pointer w-[150px] h-[150px]">
                                        <img
                                            src={input.image == null ? photo : URL.createObjectURL(input.image)}
                                            alt="File Upload"
                                            className={input.image == null ? "w-[75px] h-[75px]" : "rounded-lg w-[150px] h-[150px] lg:w-[150px] lg:h-[150px]"}
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="image"
                                            className="hidden"
                                            onChange={(event) => handleInputChange(index, event)}
                                        />
                                    </label>
                                </div>

                                {index != 0 ? <button className="rounded-lg bg-[#AF1F24] text-[#fff] w-[50px] h-[50px] text-[25px] flex items-center justify-center cursor-pointer"
                                    onClick={() => handleRemoveInput(index)} >-</button> : <button
                                        className="rounded-lg bg-[#148342] text-[#fff] w-[50px] h-[50px] text-[25px] flex items-center justify-center cursor-pointer"
                                        onClick={handleAddInput}>+</button>}
                            </div>

                        ))}

                    </div>

                </div>
            </div>
        </div>
    )
}
export default NewPostForm;

const LoadingPopup = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <p className="text-xl font-semibold">Loading...</p>
      </div>
    </div>
  );