import React from "react";
import NewCategoryForm from "../Components/Services/NewCategory/NewCategoryForm";

function NewCategory() {
  return (
    <div className="p-9">
      <NewCategoryForm />
    </div>
  );
}

export default NewCategory;
