import React, { useEffect, useState } from 'react'
import delet from '../../../Assets/Images/delete.svg';
import edit from '../../../Assets/Images/edit.svg';
import Popup from 'reactjs-popup';
import { Link, useParams } from 'react-router-dom';
import { useHome } from '../../../Context/HomeContext';

function AllPagesTable() {
  const { getPages, isPages } = useHome();
  const { lang } = useParams();

  useEffect(() => {
    getPages(lang);
  }, []);

  return (
    <div className='bg-[#fff] rounded-2xl p-3'>
      <table className='w-full border-collapse mb-6'>
        <thead>
          <tr className='p-2'>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>SL No</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Name</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {isPages?.data?.map((Page, index) => (
            <tr key={index}>
              <td className='p-5 text-start' style={{ width: '20%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
              <td className='p-5 text-start' style={{ width: '20%' }}>{Page.title}</td>
              <td className='p-5 text-center' style={{ width: '20%' }}>
                <div className='flex gap-2 items-center'>
                  <Link to={`/Pages/EditPage/${Page.id}`}>
                    <img src={edit} alt="" className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border' />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllPagesTable