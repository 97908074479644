import React from "react";
import EditServiceForm from "../Components/Services/EditService/EditServiceForm";

function EditService() {
  return (
    <div className="p-9">
      <EditServiceForm />
    </div>
  );
}

export default EditService;
