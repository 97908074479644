import React from "react";
import NewPostForm from "../Components/News/NewPost/NewPostForm";

function NewPost() {
  return (
    <div className="p-9">
      <NewPostForm />
    </div>
  );
}

export default NewPost;
