import React from "react";
import Btn from "../Components/About/AllAbout/Btn";
import AllAboutTable from "../Components/About/AllAbout/AllAboutTable";

function About() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllAboutTable />
      </div>
    </div>
  );
}

export default About;
