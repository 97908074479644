import React from "react";
import Transl from "../Components/Transl/Transl";
import Btn from "../Components/Services/AllServices/Btn";
import AllServicesTable from "../Components/Services/AllServices/AllServicesTable";

function AllServices() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllServicesTable />
      </div>
    </div>
  );
}

export default AllServices;
