import React from "react";
import EditBannerForm from "../Components/Home/Banner/EditBanner/EditBannerForm";

function EditBanner() {
  return (
    <div className="p-9">
      <EditBannerForm />
    </div>
  );
}

export default EditBanner;