import React, { useState } from "react";
import photo from "../../../Assets/Images/Vector.svg";
import add from "../../../Assets/Images/add.png";
import remove from "../../../Assets/Images/remove.png";
import { useNavigate } from "react-router-dom";
import { newBaseUrl } from "../../../Consts";
import axios from "axios";
import { useCareers } from "../../../Context/CareersContext";

function NewCareerForm() {
    const [selectedLanguage, setSelectedLanguage] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(1);
    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Careers", { replace: true });
    const [langShowError, setLangShowError] = useState(false);
    const [categoryShowError, setCategoryShowError] = useState(false);
    const [imageShowError, setImageShowError] = useState(false);
    const [titleShowError, setTitleShowError] = useState(false);
    const [excerptShowError, setExcerptShowError] = useState(false);
    const [descriptionShowError, setDescriptionShowError] = useState(false);


    const handleSaveClick = async () => {
        if (selectedLanguage === 0) {
            setLangShowError(true)
        } else if (selectedLanguage !== 0) {
            setLangShowError(false)
        } if (document.getElementById('title').value === "") {
            setTitleShowError(true)
        } else if (document.getElementById('title').value !== "") {
            setTitleShowError(false)
        } if (document.getElementById('excerpt').value === "") {
            setExcerptShowError(true)
        } else if (document.getElementById('excerpt').value !== "") {
            setExcerptShowError(false)
        } if (langShowError === false &&
            titleShowError === false && excerptShowError === false) {
            try {
                const body = {
                    'lang_id': selectedLanguage == 0 ? null : selectedLanguage,
                    'title': document.getElementById('title').value,
                    'excerpt': document.getElementById('excerpt').value,
                    'details': document.getElementById('details').value,
                    'location': document.getElementById('location').value,
                    'position': document.getElementById('position').value,
                    'emp_status': document.getElementById('emp_status').value,
                    'skills': document.getElementById('skills').value,
                    'education': document.getElementById('education').value,
                    'status': selectedStatus,
                };

                const response = await axios.post(`${newBaseUrl}/api/store/career`, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    },
                });
                console.log(response.status);
                handleGoToMenu();
                // console.log(res);
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <div>
            <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px] w-full">
                <div style={{ width: '100%' }}>
                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Language
                    </label>
                    <select
                        name="Language"
                        id="language"
                        value={selectedLanguage}
                        onChange={(e) => {
                            setSelectedLanguage(e.target.value);
                            console.log(e.target.value); // Log the current value from the event
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value='0'>Select Language</option>
                        <option value="1">English</option>
                        <option value="2">Arabic</option>
                    </select>


                    {langShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please select Language.</p>}

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Title
                    </label>

                    <input
                        type={'text'}
                        id={'title'}
                        name={'Title'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type title here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    {titleShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Title.</p>}

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Job Location
                    </label>

                    <input
                        type={'text'}
                        id={'location'}
                        name={'Location'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type Job Location here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Job Position
                    </label>

                    <input
                        type={'text'}
                        id={'position'}
                        name={'Position'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type Job Position here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Employment Status
                    </label>

                    <input
                        type={'text'}
                        id={'emp_status'}
                        name={'EmpStatus'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type Employment Status here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Excerpt
                    </label>

                    <textarea
                        id={'excerpt'}
                        name={'Excerpt'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type excerpt here`}
                        style={{
                            height: "120px",
                        }}
                    />

                    {excerptShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Excerpt.</p>}

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Job Details
                    </label>

                    <textarea
                        id={'details'}
                        name={'Details'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type Job Details here`}
                        style={{
                            height: "120px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Skills
                    </label>

                    <textarea
                        id={'skills'}
                        name={'Skills'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type Skills here`}
                        style={{
                            height: "120px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Education
                    </label>

                    <textarea
                            id={'education'}
                        name={'Education'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type Education here`}
                        style={{
                            height: "120px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Status
                    </label>

                    <select
                        name="Status"
                        id="status"
                        value={selectedStatus}
                        onChange={(e) => {
                            setSelectedStatus(e.target.value);
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value="1">Publish</option>
                        <option value="2">Draft</option>
                    </select>
                    <div>
                        <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                            onClick={handleSaveClick}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewCareerForm;
