import React from "react";
import EditPostForm from "../Components/News/EditPost/EditPostForm";

function EditPost() {
  return (
    <div className="p-9">
      <EditPostForm />
    </div>
  );
}

export default EditPost;
