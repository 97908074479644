import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"

export const ProductsContext = createContext();
export const useProducts = () => {
    return useContext(ProductsContext);
};

export default function ProductsContextProvider({ children }) {
    const [isProducts, setIsProducts] = useState()
    const [isProductById, setIsProductById] = useState()
    const [isProductsCategory, setIsProductsCategory] = useState()
    const [isProductsCategoryById, setIsProductsCategoryById] = useState()
    const [isProductsSubCategory, setIsProductsSubCategory] = useState()
    const [isProductsSubCategoryById, setIsProductsSubCategoryById] = useState()
    const [isProductsBrand, setIsProductsBrand] = useState()
    const [isProductsBrandById, setIsProductsBrandById] = useState()
    const [isProductsSubBrand, setIsProductsSubBrand] = useState()
    const [isProductsSubBrandById, setIsProductsSubBrandById] = useState()
    const [isSubCategoryById, setIsSubCategoryById] = useState()
    const [isSubBrandById, setIsSubBrandById] = useState()

    async function getProducts(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products`, { headers });
            setIsProducts(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductById(id) {
        setIsProductById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/editproduct/${id}`, { headers });
            setIsProductById(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteProduct(id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/product/${id}`, { headers });
            // setIsProducts(res?.data);
            console.log(res?.data);
            getProducts()
        } catch (err) {
            console.error(err);
        }
    }

    async function getSubCategoryById(id) {
        setIsSubCategoryById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/subcategories/${id}`, { headers });
            setIsSubCategoryById(res?.data)
            console.log(res?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getSubBrandById(id) {
        setIsSubBrandById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/subbrands/${id}`, { headers });
            setIsSubBrandById(res?.data)
            console.log(res?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsCategory(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products-category`, { headers });
            setIsProductsCategory(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsCategoryById(id) {
        setIsProductsCategoryById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products-category/${id}`, { headers });
            setIsProductsCategoryById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteProductsCategory(id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/products-category/${id}`, { headers });
            // setIsProducts(res?.data);
            console.log(res?.data);
            getProductsCategory()
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsSubCategory(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products-subcategory`, { headers });
            setIsProductsSubCategory(res?.data)
            console.log(res?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsSubCategoryById(id, lang) {
        setIsProductsSubCategoryById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products-subcategory/${id}`, { headers });
            setIsProductsSubCategoryById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteProductsSubCategory(id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/products-subcategory/${id}`, { headers });
            // setIsProducts(res?.data);
            console.log(res?.data);
            getProductsSubCategory()
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsBrand(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products-brand`, { headers });
            setIsProductsBrand(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsBrandById(id) {
        setIsProductsBrandById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products-brand/${id}`, { headers });
            setIsProductsBrandById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteProductsBrand(id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/products-brand/${id}`, { headers });
            // setIsProducts(res?.data);
            console.log(res?.data);
            getProductsBrand()
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsSubBrand(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products-subbrand`, { headers });
            setIsProductsSubBrand(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getProductsSubBrandById(id) {
        setIsProductsSubBrandById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/products-subbrand/${id}`, { headers });
            setIsProductsSubBrandById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteProductsSubBrand(id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/products-subbrand/${id}`, { headers });
            // setIsProducts(res?.data);
            console.log(res?.data);
            getProductsSubBrand()
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <ProductsContext.Provider value={{
            getProducts, getProductById, getProductsCategory, deleteProductsCategory, getProductsCategoryById, getProductsSubCategory, getProductsSubCategoryById,
            deleteProductsSubCategory, getProductsBrand, getProductsBrandById, deleteProductsBrand, getProductsSubBrand, getProductsSubBrandById, deleteProductsSubBrand,
            isProducts, isProductById, isProductsCategory, isProductsCategoryById, isProductsSubCategory, isProductsSubCategoryById, isProductsBrand, isProductsBrandById,
            isProductsSubBrand, isProductsSubBrandById, deleteProduct, getSubCategoryById, isSubCategoryById, isSubBrandById, getSubBrandById
        }}>
            {children}
        </ProductsContext.Provider>
    );
}