import React from "react";
import Transl from "../Components/Transl/Transl";
import SubBrandTable from "../Components/Products/SubBrand/SubBrandTable";
import Btn from "../Components/Products/SubBrand/Btn";

function SubBrandOfProducts() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <SubBrandTable />
      </div>
    </div>
  );
}

export default SubBrandOfProducts;
