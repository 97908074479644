import React from "react";
import NewProjectForm from "../Components/Projects/NewProject/NewProjectForm";

function NewProject() {
  return (
    <div className="p-9">
      <NewProjectForm />
    </div>
  );
}

export default NewProject;
