import React from "react";
import NewBrandForm from "../Components/Products/NewBrand/NewBrandForm";

function NewBrandOfProducts() {
  return (
    <div className="p-9">
      <NewBrandForm />
    </div>
  );
}

export default NewBrandOfProducts;
