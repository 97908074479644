import React, { useEffect, useState } from 'react'
import edit from '../../../Assets/Images/edit.svg';
import Pagination from '../../Pagination/Pagination';
import { Link } from 'react-router-dom';
import { useAbout } from '../../../Context/AboutContext';
import { useParams } from 'react-router-dom/dist';

function AllAboutTable() {
  const { isAbout, getAbout } = useAbout()
  const { lang } = useParams();

  useEffect(() => {
    getAbout(lang);
  }, []);

  return (
    <div className='bg-[#fff] rounded-2xl p-3'>
      <table className='w-full border-collapse mb-6'>
        <thead>
          <tr className='p-2'>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>SL No</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '30%' }}>Image</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '30%' }}>Title</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '30%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {isAbout?.data?.map((about, index) => (
            <tr key={about.id}>
              <td className='p-5 text-start' style={{ width: '20%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
              <td className='p-5 text-start' style={{ width: '30%' }}><img src={about.image_url} alt="" className='w-[125px] h-[125px] rounded-xl' /></td>
              <td className='p-5 text-start' style={{ width: '30%' }}>{about.title}</td>
              <td className='p-5 text-center' style={{ width: '30%' }}>
                <div className='flex gap-2 items-center'>
                  <Link to={`/About/EditAbout/${about.id}`}>
                    <img src={edit} alt="" className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border' />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllAboutTable