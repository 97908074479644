import React from "react";

import NewSubCategoryForm from "../Components/Products/NewSubCategory/NewSubCategoryForm";

function NewSubCategoryOfProducts() {
  return (
    <div className="p-9">
      <NewSubCategoryForm />
    </div>
  );
}

export default NewSubCategoryOfProducts;
