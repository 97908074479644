import React from "react";
import Transl from "../Components/Transl/Transl";
import Btn from "../Components/Home/Partners/AllPartners/Btn";
import AllPartnersTable from "../Components/Home/Partners/AllPartners/PartnersTable";

function AllPartners() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllPartnersTable />
      </div>
    </div>
  );
}

export default AllPartners;
