import React from "react";
import Transl from "../Components/Transl/Transl";
import Btn from "../Components/Home/Counters/AllCounters/Btn";
import AllCountersTable from "../Components/Home/Counters/AllCounters/CountersTable";

function AllCounters() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllCountersTable />
      </div>
    </div>
  );
}

export default AllCounters;
