import React from "react";
import EditBrandForm from "../Components/Products/EditBrand/EditBrandForm";
import EditSubBrandForm from "../Components/Products/EditSubBrand/EditSubBrandForm";

function EditProductsSubBrand() {
  return (
    <div className="p-9">
      <EditSubBrandForm />
    </div>
  );
}

export default EditProductsSubBrand;
