import React from 'react';
import delet from "../../../Assets/Images/Delet-modal.svg";
import { useParams } from 'react-router-dom/dist';

function DeletModal({ close, id, onDelete }) {
  const { lang } = useParams();

  const handleDelete = async () => {
    try {
      await onDelete(id, lang);
      close();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center py-10 px-20 gap-4 w-[500px]">
      <div>
        <img src={delet} alt="" className="w-[100px]" />
      </div>
      <div className="flex flex-col items-center justify-center gap-3">
        <h2 className="text-[25px] font-[Cairo] font-[700]">
          Are you sure you want to delete the item?
        </h2>
        <p className="text-center w-[36vw] text-[16px] font-[Cairo] font-[400]">
          You will not be able to recover this item again
        </p>
      </div>
      <div className="actions my-2 flex gap-3 px-5">
        <button
          className="button rounded-lg border border-[#1C1C1A80] text-[#1C1C1A80] font-[500] text-[20px] py-[5px] px-[2.5rem] font-[Cairo]"
          onClick={() => {
            console.log("modal closed ");
            close();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="bg-[#D92626] rounded-md block m-auto text-white font-[500] text-[20px] py-[5px] px-[2.5rem] font-[Cairo]"
          onClick={handleDelete}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default DeletModal;
