import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function Pagination() {const [activePage, setActivePage] = useState(null);

    const handlePageClick = (page) => {
      setActivePage(page);
    };
  
    return (
      <div className="block ms-auto">
        <ol className="flex justify-center text-xs font-medium space-x-1">
          <li>
            <Link
              className={`inline-flex items-center justify-center text-[20px] w-8 h-8 border border-[#0966AE] text-[#0966AE] ${
                activePage === "special" ? "bg-[#0966AE] text-white" : ""
              } rounded`}
              onClick={() => handlePageClick("special")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M3 9L2.29289 8.29289L1.58579 9L2.29289 9.70711L3 9ZM14.25 10C14.8023 10 15.25 9.55228 15.25 9C15.25 8.44772 14.8023 8 14.25 8V10ZM6.79289 3.79289L2.29289 8.29289L3.70711 9.70711L8.20711 5.20711L6.79289 3.79289ZM2.29289 9.70711L6.79289 14.2071L8.20711 12.7929L3.70711 8.29289L2.29289 9.70711ZM3 10H14.25V8H3V10Z"
                  fill={activePage === "special" ? "white" : "#0966AE"}
                />
              </svg>
            </Link>
          </li>
  
          {[1, 2, 3, 4].map((page) => (
            <li key={page}>
              <Link
                className={`inline-flex items-center justify-center text-[20px] w-8 h-8 text-[#0966AE] border border-[#0966AE] ${
                  activePage === page ? "bg-[#0966AE] text-white" : ""
                } rounded`}
                onClick={() => handlePageClick(page)}
              >
                {page}
              </Link>
            </li>
          ))}
  
          <li>
            <Link
              className={`inline-flex items-center justify-center text-[20px] w-8 h-8 text-[#0966AE] border border-[#0966AE] ${
                activePage === "anotherSpecial" ? "bg-[#0966AE] text-white" : ""
              } rounded`}
              onClick={() => handlePageClick("anotherSpecial")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M15 9L15.7071 9.70711L16.4142 9L15.7071 8.29289L15 9ZM3.75 8C3.19771 8 2.75 8.44772 2.75 9C2.75 9.55228 3.19771 10 3.75 10V8ZM11.2071 14.2071L15.7071 9.70711L14.2929 8.29289L9.79289 12.7929L11.2071 14.2071ZM15.7071 8.29289L11.2071 3.79289L9.79289 5.20711L14.2929 9.70711L15.7071 8.29289ZM15 8L3.75 8V10L15 10V8Z"
                  fill={activePage === "anotherSpecial" ? "white" : "#0966AE"}
                />
              </svg>
            </Link>
          </li>
        </ol>
      </div>
    );
}

export default Pagination