import React from 'react'
import { useAuth } from '../../Context/AuthContext'
import { Navigate } from 'react-router-dom'

function ProtectedRoute({ children }) {
    const { isAuth } = useAuth()


    if (isAuth) {
        return children
    } else {
        return <Navigate to={'/Login'} />
    }


}

export default ProtectedRoute