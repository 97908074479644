import { createContext, useContext, useState } from "react";

export const AuthContext = createContext();
export const useAuth = () => {
    return useContext(AuthContext);
};

export default function AuthContextProvider({ children }) {

    const [isAuth, setIsAuth] = useState(localStorage.getItem('token') ? true : false)

    return (
        <AuthContext.Provider value={{
            setIsAuth, isAuth
        }}>
            {children}
        </AuthContext.Provider>
    )

}