import React from "react";
import EditProjectForm from "../Components/Projects/EditProject/EditProjectForm";

function EditProject() {
  return (
    <div className="p-9">
      <EditProjectForm />
    </div>
  );
}

export default EditProject;
