import React, { useEffect, useState } from 'react';
import axios from "axios";
import { newBaseUrl } from "../../../Consts";
import { useNavigate, useParams } from 'react-router-dom';
import { useHome } from '../../../Context/HomeContext';
import { ToastContainer , toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function EditPageForm() {
    const [editorData, setEditorData] = useState('');
    const { isPageById, getPageById } = useHome();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [editorLoaded, setEditorLoaded] = useState(false);

    useEffect(() => {
        getPageById(id);
    }, []);

    useEffect(() => {
        if (isPageById) {
            setEditorData(isPageById.data.content);
            setEditorLoaded(true); // Set editorLoaded to true when the content is available
        }
    }, [isPageById]);

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${newBaseUrl}/api/update/page/${id}`, {
                content: editorData
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            toast.success('Page updated successfully!', {
                onClose: () => {
                    navigate(`/Pages/${isPageById?.data.lang_id === 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });
        } catch (err) {
            console.error(err);
            toast.error('Error updating page. Please try again.');
            setLoading(false);
        }
    };

    return (
        <div>
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {isPageById ? (
                <div className="scrollable-content flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div style={{ width: '100%' }}>
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">
                            Title
                        </label>
                        <input type={'text'} id={'title'} name={'Title'} disabled={true}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isPageById?.data?.title}
                            style={{ paddingBottom: "8px" }}
                        />
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">
                            Content
                        </label>
                        {editorLoaded && ( // Render CKEditor only when it's fully loaded
                            <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                }}
                            />
                        )}
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Save'}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    )
}

export default EditPageForm;
