import React from "react";
import NewPartnerForm from "../Components/Home/Partners/NewPartner/NewPartnerForm";

function NewPartner() {
  return (
    <div className="p-9">
      <NewPartnerForm />
    </div>
  );
}

export default NewPartner;