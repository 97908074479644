import React from "react";
import Btn from "../Components/News/AllNews/Btn";
import AllNewsTable from "../Components/News/AllNews/AllNewsTable";

function AllNews() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllNewsTable />
      </div>
    </div>
  );
}

export default AllNews;
