import React from "react";
import EditPageForm from "../Components/Pages/EditPage/EditPageForm";

function EditPage() {
  return (
    <div className="p-9">
      <EditPageForm />
    </div>
  );
}

export default EditPage;