import React, { useEffect, useState } from "react";
import logo from "../../Assets/Images/logo.png";
import { useFormik } from "formik";
import "./LogIn.css";
import show from "../../Assets/Images/eye-black.svg";
import close from "../../Assets/Images/eye-slash.svg";
import axios from "axios";
import { newBaseUrl } from "../../Consts";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

function LogIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [apiError, setApiError] = useState(null); // State to hold API error message
  const { isAuth, setIsAuth } = useAuth();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getPasswordInputType = () => {
    return showPassword ? "text" : "password";
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/Banner");
    }
  }, [isAuth, navigate]);

  async function logIn(values) {
    try {
      const res = await axios.post(`${newBaseUrl}/api/login`, values);
      if (res?.data?.data?.access_token != null) {
        localStorage.setItem("token", res?.data?.data?.access_token);
        setIsAuth(true);
        navigate("/Home/AllBanners/en");
      } else {
        setApiError(res?.data?.message || "An error occurred"); // Set error message from API response
      }
    } catch (err) {
      console.error(err);
      setApiError("An error occurred"); // Set error message from API response
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: logIn,
    validate: values => {
      const errors = {};

      // Custom validation for email
      if (!values.email) {
        errors.email = "Email is required";
      } else if (!isValidEmail(values.email)) {
        errors.email = "Invalid email address";
      }

      // Custom validation for password
      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password.length < 8) {
        errors.password = "Password must be at least 8 characters long";
      }

      return errors;
    },
  });

  function isValidEmail(email) {
    // Custom email validation logic
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const handleInputChange = () => {
    setApiError(null); // Reset apiError state when any input field value changes
  };

  return (
    <div className="bg-[#fff] flex justify-center items-center h-screen">
      <div className="flex flex-col items-center shadow-lg py-8 px-10 gap-10 rounded-lg">
        <img src={logo} alt="" className="w-40" />
        <form className="flex flex-col gap-6 w-80" onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-3">
            <input
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={(e) => { formik.handleChange(e); handleInputChange(); }}
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              className="border rounded-lg text-[16px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="flex flex-row text-red-500">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="flex flex-col gap-3 relative">
            <input
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={(e) => { formik.handleChange(e); handleInputChange(); }}
              type={getPasswordInputType()}
              id="password"
              name="password"
              placeholder="Password"
              className="border rounded-lg text-[16px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="flex flex-row text-red-500">{formik.errors.password}</div>
            ) : null}
            {apiError && <div className="text-red-500">{apiError}</div>} {/* Display API error message */}
            <span
              onClick={togglePasswordVisibility}
              className="absolute right-2 top-2 cursor-pointer"
            >
              {showPassword ? <img src={show} alt="show" /> : <img src={close} alt="close" />}
            </span>
          </div>
          <button
            type="submit"
            className="rounded-full py-2 text-white text-lg font-semibold bg-[#AF1F24] hover:bg-red-700"
          >
            LOGIN
          </button>
        </form>
      </div>
    </div>
  );
}

export default LogIn;
