import React from "react";
import EditCategoryForm from "../Components/Services/EditCategory/EditCategoryForm";

function EditCategory() {
  return (
    <div className="p-9">
      <EditCategoryForm />
    </div>
  );
}

export default EditCategory;
