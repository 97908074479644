import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useProducts } from '../../../Context/ProductsContext';

function EditSubCategoryForm() {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const { isProductsSubCategoryById, getProductsSubCategoryById } = useProducts()

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Products/SubCategories", { replace: true });

    useEffect(() => {
        getProductsSubCategoryById(id);
    }, []);

    useEffect(() => {
        if (isProductsSubCategoryById?.data?.subcategory?.category_id) {
            setSelectedCategory(isProductsSubCategoryById?.data?.subcategory?.category_id);
        }
        if (isProductsSubCategoryById?.data?.subcategory?.status) {
            setSelectedStatus(isProductsSubCategoryById?.data?.subcategory?.status);
        }
    }, [isProductsSubCategoryById]);

    const handleSaveClick = async () => {
        try {
            const body = {
                'name': document.getElementById('name').value,
                'category_id': selectedCategory,
                'status': selectedStatus,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/products-subcategory/${isProductsSubCategoryById?.data?.subcategory?.id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            console.log(response.status);
            handleGoToMenu();
            // console.log(res);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            {isProductsSubCategoryById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Name
                        </label>

                        <input
                            type={'text'}
                            id={'name'}
                            name={'Name'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type name here`}
                            defaultValue={isProductsSubCategoryById?.data?.subcategory?.name}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Category
                        </label>

                        <select
                            name="Category"
                            id="category"
                            value={selectedCategory}
                            onChange={(e) => {
                                setSelectedCategory(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            {isProductsSubCategoryById?.data?.categories.map((category, index) => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditSubCategoryForm