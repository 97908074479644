import React from "react";
import EditCategoryForm from "../Components/Products/EditCategory/EditCategoryForm";

function EditProductsCategory() {
  return (
    <div className="p-9">
      <EditCategoryForm />
    </div>
  );
}

export default EditProductsCategory;
