import React, { useEffect, useState } from 'react';
import photo from "../../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../../Consts";
import { useNavigate, useParams } from 'react-router-dom';
import { useHome } from '../../../../Context/HomeContext';
import { ToastContainer, toast } from 'react-toastify';

function EditBannerForm(pageTitle="Services Category") {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const { isBannerById, getBannerById } = useHome();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        getBannerById(id);
    }, []);

    const handleSaveClick = async () => {
        try {
            setLoading(true);
            const body = {
                'title': document.getElementById('title').value,
                'order': document.getElementById('order').value,
                'excerpt': document.getElementById('excerpt').value,
                'status': selectedStatus !== 0 ? selectedStatus : isBannerById?.data.status,
                'image': selectedFile,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/banner/${id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            toast.success('Banner updated successfully!', {
                onClose: () => {
                    navigate(`/Home/AllBanners/${isBannerById?.data.lang_id === 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });
        } catch (err) {
            toast.error('Error updating banner. Please try again.');
            console.error(err);
            setLoading(false);
        }
    };

    return (
        <div>
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false}/>
            {isBannerById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div className="flex flex-col gap-5 pr-[50px]">
                        <div className="flex rounded-lg bg-[#F2F4F7]">
                            <label htmlFor="fileInput" className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]">
                                <img src={isBannerById?.data.image_url != null && selectedFile == null ? isBannerById?.data.image_url :
                                    isBannerById?.data.image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                    alt="File Upload"
                                    className={selectedFile == null && isBannerById?.data.image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}
                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                        <div>
                            <button 
                                className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Save'}
                            </button>
                        </div>
                    </div>
                    <div className="pl-[50px]" style={{ width: '100%' }}>
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">Name</label>
                        <input type={'text'} id={'title'} name={'Title'} className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style" placeholder={`Type name here`} defaultValue={isBannerById?.data.title} style={{ paddingBottom: "8px" }} />
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">Excerpt</label>
                        <textarea id={'excerpt'} name={'Excerpt'} className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style" placeholder={`Type excerpt here`} defaultValue={isBannerById?.data.excerpt} style={{ height: "80px" }} />
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">Order</label>
                        <input type={'number'} id={'order'} name={'Order'} className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style" placeholder={`Type order here`} defaultValue={isBannerById?.data.order} style={{ paddingBottom: "8px" }} />
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">Status</label>
                        <select name="Status" id="status" value={selectedStatus !== 0 ? selectedStatus : isBannerById?.data.status} onChange={(e) => { setSelectedStatus(e.target.value); }} className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]">
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default EditBannerForm;
