import React from "react";
import NewServiceForm from "../Components/Services/NewService/NewServiceForm";

function NewService() {
  return (
    <div className="p-9">
      <NewServiceForm />
    </div>
  );
}

export default NewService;
