import React from "react";
import NewCategoryForm from "../Components/Projects/NewCategory/NewCategoryForm";

function NewCategoryOfProjects() {
  return (
    <div className="p-9">
      <NewCategoryForm />
    </div>
  );
}

export default NewCategoryOfProjects;
