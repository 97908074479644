import React, { useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate } from 'react-router-dom';

function NewCategoryForm() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const navigate = useNavigate();
  const handleGoToMenu = () => navigate("/Services/Categories", { replace: true });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSaveClick = async () => {
    try {
      const body = {
        lang_id: selectedLanguage,
        name: document.getElementById("name").value,
        excerpt: document.getElementById("excerpt").value,
        description: "description",
        status: selectedStatus,
        image: selectedFile,
      };
      const response = await axios.post(`${newBaseUrl}/api/store/services-category`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      });
      console.log(response.status);
      handleGoToMenu();
      // console.log(res);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
        <div className="flex flex-col gap-5 pr-[50px]">
          <div
            className="flex rounded-lg bg-[#F2F4F7]"
          >
            <label
              htmlFor="fileInput"
              className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
            >
              <img
                src={selectedFile == null ? photo : URL.createObjectURL(selectedFile)}
                alt="File Upload"
                className={selectedFile == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}

              />
              <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileChange}
              />
            </label>
          </div>
          <div>
            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </div>
        <div className="pl-[50px]" style={{ width: '100%' }}>
          <label
            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
          >
            Language
          </label>
          <select
            name="Language"
            id="language"
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.target.value);
            }}
            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
          >
            <option value='0'>Select Language</option>
            <option value="1">English</option>
            <option value="2">Arabic</option>
          </select>

          <label
            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
          >
            Name
          </label>

          <input
            type={'text'}
            id={'name'}
            name={'Name'}
            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
            placeholder={`Type name here`}
            style={{
              paddingBottom: "8px",
            }}
          />

          <label
            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
          >
            Excerpt
          </label>

          <textarea
            type={'text'}
            id={'excerpt'}
            name={'Excerpt'}
            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
            placeholder={`Type excerpt here`}
            style={{
              height: "80px",
              paddingBottom: "3rem",
            }}
          />

          <label
            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
          >
            Status
          </label>

          <select
            name="Status"
            id="status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
          >
            <option value="1">Publish</option>
            <option value="2">Draft</option>
          </select>

        </div>
      </div>
    </div>
  )
}

export default NewCategoryForm