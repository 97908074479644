import React from "react";
import Btn from "../Components/Pages/AllPages/Btn";
import AllPagesTable from "../Components/Pages/AllPages/PagesTable";

function AllPages() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllPagesTable />
      </div>
    </div>
  );
}

export default AllPages;
