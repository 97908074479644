import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import remove from "../../../Assets/Images/remove.png";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useProducts } from '../../../Context/ProductsContext';

function EditProductForm() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedSubCategory, setSelectedSubCategory] = useState(0);
    const [selectedBrand, setSelectedBrand] = useState(0);
    const [selectedSubBrand, setSelectedSubBrand] = useState(0);
    const { getSubCategoryById, isSubCategoryById } = useProducts();
    const { getSubBrandById, isSubBrandById } = useProducts();
    const { isProductById, getProductById } = useProducts()
    const { deleteProductGalleryImage } = useProducts();

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Products/AllProducts", { replace: true });

    const handleFilesChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
        event.target.value = null;
        console.log(selectedFiles);
    };

    const handleRemoveImage = (indexToRemove, id) => {
        // deleteProductGalleryImage(id)
        const updatedFiles = selectedFiles.filter((file, index) => index !== indexToRemove);
        setSelectedFiles(updatedFiles);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        getProductById(id).then((data) => {

        }).catch((error) => {
            console.error('Error fetching Post by ID:', error);
        });
    }, []);

    useEffect(() => {
        if (isProductById?.data?.main_product?.status) {
            setSelectedStatus(isProductById.data.main_product.status);
        }
        if (isProductById?.data?.main_product?.product_cat_id) {
            setSelectedCategory(isProductById?.data?.main_product?.product_cat_id);
            console.log(selectedCategory);
        }
        if (isProductById?.data?.main_product?.product_sub_cat_id) {
            setSelectedSubCategory(isProductById?.data?.main_product?.product_sub_cat_id);
            console.log(selectedSubCategory);
        }
        if (isProductById?.data?.main_product?.product_brand_id) {
            setSelectedBrand(isProductById?.data?.main_product?.product_brand_id);
            console.log(selectedBrand);
        }
        if (isProductById?.data?.main_product?.product_sub_brand_id) {
            setSelectedSubBrand(isProductById?.data?.main_product?.product_sub_brand_id);
            console.log(selectedSubBrand);
        }
        if (isProductById?.data?.main_product?.gallery) {
            setSelectedFiles(isProductById.data.main_product.gallery);
        }
    }, [isProductById]);

    const handleSaveClick = async () => {
        try {
            const body = {
                'title': document.getElementById('title').value,
                'excerpt': document.getElementById('excerpt').value,
                'description': document.getElementById('description').value,
                'sku': document.getElementById('sku').value,
                'product_cat_id': selectedCategory,
                'product_sub_cat_id': selectedSubCategory,
                'product_brand_id': selectedBrand,
                'product_sub_brand_id': selectedSubBrand,
                'status': selectedStatus,
                'image': selectedFile,
            };

            if (selectedFiles) {
                body['gallery_images'] = selectedFiles;
            }

            console.log(body);
            const response = await axios.post(`${newBaseUrl}/api/update/product/${id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            console.log(response.status);
            handleGoToMenu();
            // console.log(res);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            {isProductById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[50px] h-[100%]">
                    <div className="flex flex-col gap-5 pr-[50px]">
                        <div className="flex rounded-lg bg-[#F2F4F7]">
                            <label
                                htmlFor="fileInput"
                                className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]">
                                <img
                                    src={isProductById?.data?.main_product?.image_url != null && selectedFile == null ? isProductById?.data?.main_product?.image_url :
                                        isProductById?.data?.main_product?.image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                    alt="File Upload"
                                    className={selectedFile == null && isProductById?.data?.main_product?.image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}
                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}>
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="scrollable-content pl-[50px]" style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >

                            Title
                        </label>

                        <input
                            type={'text'}
                            id={'title'}
                            name={'Title'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isProductById?.data?.main_product?.title}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            SKU
                        </label>

                        <input
                            type={'text'}
                            id={'sku'}
                            name={'SKU'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isProductById?.data?.main_product?.sku}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Category
                        </label>

                        <select
                            name="category"
                            id="category"
                            value={selectedCategory}
                            onChange={(e) => {
                                setSelectedCategory(e.target.value);
                                getSubCategoryById(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            {isProductById?.data?.categories.map((Product, index) => (
                                <option key={Product.id} value={Product.id}>{Product.name}</option>
                            ))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Sub Category
                        </label>

                        <select
                            name="subCategory"
                            id="sub_category"
                            value={selectedSubCategory}
                            onChange={(e) => {
                                setSelectedSubCategory(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value='0'>Select Sub Category</option>
                            {isSubCategoryById?.data == null ? (isProductById?.data?.subCategories.map((Product, index) => (
                                <option key={Product.id} value={Product.id}>{Product.name}</option>
                            ))) : (isSubCategoryById?.data?.map((Product, index) => (
                                <option key={Product.id} value={Product.id}>{Product.name}</option>
                            )))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Brand
                        </label>

                        <select
                            name="Brand"
                            id="brand"
                            value={selectedBrand}
                            onChange={(e) => {
                                setSelectedBrand(e.target.value);
                                getSubBrandById(e.target.value)
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            {isProductById?.data?.brands.map((Product, index) => (
                                <option key={Product.id} value={Product.id}>{Product.name}</option>
                            ))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Sub Brand
                        </label>

                        <select
                            name="subBrand"
                            id="sub_brand"
                            value={selectedSubBrand}
                            onChange={(e) => {
                                setSelectedSubBrand(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value='0'>Select Sub Brand</option>
                            {isSubBrandById?.data == null ? (isProductById?.data?.subBrands.map((Product, index) => (
                                <option key={Product.id} value={Product.id}>{Product.name}</option>
                            ))) : (isSubBrandById?.data?.map((Product, index) => (
                                <option key={Product.id} value={Product.id}>{Product.name}</option>
                            )))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Excerpt
                        </label>

                        <textarea
                            id={'excerpt'}
                            name={'Excerpt'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isProductById?.data?.main_product?.excerpt}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Description
                        </label>

                        <textarea
                            id={'description'}
                            name={'description'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isProductById?.data?.main_product?.description}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus != 0 ? selectedStatus : isProductById?.data?.main_product?.status}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Gallery
                        </label>

                        <div className="grid grid-cols-6 gap-y-5 mb-2">
                            <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]">
                                <label className="flex items-center justify-center cursor-pointer w-[150px] h-[150px]">
                                    <img
                                        src={photo}
                                        alt=""
                                        className={"w-[75px] h-[75px]"}
                                    />
                                    <input
                                        type="file"
                                        className="hidden"
                                        onChange={handleFilesChange}
                                    />
                                </label>
                            </div>

                            {selectedFiles.map((file, index) => (
                                <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]" key={index}>
                                    <div className="relative">
                                        <img
                                            src={file == null ? photo : file?.image_url != null ? file?.image_url : URL.createObjectURL(file)}
                                            alt="File Upload"
                                            className={"rounded-lg w-[150px] h-[150px] lg:w-[150px] lg:h-[150px]"}
                                        />
                                        <button
                                            className="absolute top-0 left-0 bg-transparent rounded-full p-1 m-2"
                                            onClick={() => handleRemoveImage(index)}
                                        >
                                            <img src={remove} alt="Close" className="w-4 h-4" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditProductForm