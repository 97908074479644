import React from "react";
import NewProductForm from "../Components/Products/NewProduct/NewProductForm";

function NewProduct() {
  return (
    <div className="p-9">
      <NewProductForm />
    </div>
  );
}

export default NewProduct;
