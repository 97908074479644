import React, { useEffect, useState } from 'react';
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts";
import { useNavigate, useParams } from 'react-router-dom';
import { useAbout } from '../../../Context/AboutContext';
import { ToastContainer, toast } from 'react-toastify';

function EditAbout() {
    const [selectedFile, setSelectedFile] = useState(null);
    const { isAboutById, getAboutById } = useAbout();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files;
        setSelectedFile(file);
    };

    useEffect(() => {
        getAboutById(id);
    }, []);

    const handleSaveClick = async () => {
        try {
            setLoading(true);
            const body = {
                'lang_id': isAboutById?.data.lang_id,
                'title': document.getElementById('title').value,
                'excerpt': document.getElementById('excerpt').value,
                'description': document.getElementById('description').value,
                'image': selectedFile,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/about/${isAboutById?.data.id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            toast.success('About updated successfully!', {
                onClose: () => {
                    navigate(`/About/AllAbout/${isAboutById?.data.lang_id === 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });
        } catch (err) {
            toast.error('Error updating about. Please try again.');
            console.error(err);
            setLoading(false);
        }
    };

    return (
        <div className="p-9">
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false}/>
            {isAboutById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div className="flex flex-col gap-5 pr-[50px]">
                        <div className="flex rounded-lg bg-[#F2F4F7]">
                            <label htmlFor="fileInput" className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]">
                                <img src={isAboutById?.data.image_url != null && selectedFile == null ? isAboutById?.data.image_url :
                                    isAboutById?.data.image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                    alt="File Upload"
                                    className={selectedFile == null && isAboutById?.data.image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}
                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                        <div>
                            <button 
                                className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Save'}
                            </button>
                        </div>
                    </div>
                    <div className="pl-[50px]" style={{ width: '100%' }}>
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">Title</label>
                        <input type={'text'} id={'title'} name={'title'} className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style" placeholder={`Type title here`} defaultValue={isAboutById?.data.title} style={{ paddingBottom: "8px" }} />
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">Excerpt</label>
                        <textarea id={'excerpt'} name={'Excerpt'} className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style" placeholder={`Type excerpt here`} defaultValue={isAboutById?.data.excerpt} style={{ height: "80px" }} />
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">Description</label>
                        <textarea id={'description'} name={'Description'} className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style" placeholder={`Type description here`} defaultValue={isAboutById?.data.description} style={{ height: "120px" }} />
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default EditAbout;
