import React from "react";
import EditProductForm from "../Components/Products/EditProduct/EditProductForm";

function EditProduct() {
  return (
    <div className="p-9">
      <EditProductForm />
    </div>
  );
}

export default EditProduct;
