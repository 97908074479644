import React, { useEffect, useState } from 'react'
import banner from '../../../Assets/Images/banner.jpeg';
import delet from '../../../Assets/Images/delete.svg';
import edit from '../../../Assets/Images/edit.svg';
import Popup from 'reactjs-popup';
import Pagination from '../../Pagination/Pagination';
import { useProducts } from '../../../Context/ProductsContext';
import DeletModal from './DeletModal';
import { Link } from 'react-router-dom';

function BrandTable() {
  const { getProductsBrand, isProductsBrand } = useProducts();

  useEffect(() => {
    getProductsBrand();
  }, []);

  return (
    <div className='bg-[#fff] rounded-2xl p-3'>
      <table className='w-full border-collapse mb-6'>
        <thead>
          <tr className='p-2'>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '10%' }}>SL No</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Image</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Name</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Status</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {isProductsBrand?.data?.map((product, index) => (
            <tr key={index}>
              <td className='p-5 text-start' style={{ width: '10%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
              <td className='p-5 text-start' style={{ width: '20%' }}><img src={product.image_url} alt="" className='w-[125px] h-[125px] rounded-xl' /></td>
              <td className='p-5 text-start' style={{ width: '20%' }}>{product.name}</td>
              <td className='p-5 text-start' style={{ width: '20%' }}>
                <div className={product.status == 1 ? 
                  'bg-[#0966AE] text-[#fff] p-2 w-[75px] rounded-md border text-center' : 
                  'bg-[#ffc107] p-2 w-[75px] rounded-md border text-center'}>
                  {product.status == 1 ? "Publish" : "Draft"}
                </div></td>
              <td className='p-5 text-center' style={{ width: '20%' }}>
                <div className='flex gap-2 items-center'>
                  <Link to={`/Products/EditBrand/${product.id}`}>
                  <img src={edit} alt="" className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border' />
                  </Link>
                  <Popup
                    trigger={
                      <img src={delet} alt="" className='bg-[#AF1F24] p-2 w-[40px] cursor-pointer rounded-md border' />
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <DeletModal close={close} id={product.id}/>
                      </div>
                    )}
                  </Popup>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination />
    </div>
  );
}

export default BrandTable;