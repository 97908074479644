import React from "react";
import Transl from "../Components/Transl/Transl";

import CategoryTable from "../Components/Projects/Category/CategoryTable";
import Btn from "../Components/Projects/Category/Btn";

function CategoryOfProjects() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <CategoryTable />
      </div>
    </div>
  );
}

export default CategoryOfProjects;
