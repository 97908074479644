import React from "react";
import EditSocialLinksForm from "../Components/Home/SocialLinks/EditSocialLink/EditSocialLinkForm";

function EditSocialLinks() {
  return (
    <div className="p-9">
      <EditSocialLinksForm />
    </div>
  );
}

export default EditSocialLinks;
