import React from "react";
import Transl from "../Components/Transl/Transl";
import Btn from "../Components/Products/AllProducts/Btn";
import AllProductsTable from "../Components/Products/AllProducts/AllProductsTable";

function AllProducts() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllProductsTable />
      </div>
    </div>
  );
}

export default AllProducts;
