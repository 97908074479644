import React from "react";
import EditBrandForm from "../Components/Products/EditBrand/EditBrandForm";

function EditProductsBrand() {
  return (
    <div className="p-9">
      <EditBrandForm />
    </div>
  );
}

export default EditProductsBrand;
