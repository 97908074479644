import React from "react";
import Transl from "../Components/Transl/Transl";
import AllProjectTable from "../Components/Projects/AllProject/AllProjectTable";
import Btn from "../Components/Projects/AllProject/Btn";

function AllProject() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Btn />
      </div>
      <div className="px-10">
        <AllProjectTable />
      </div>
    </div>
  );
}

export default AllProject;
