import React from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Layout from './Layout/Layout';
import Banner from './Pages/Banner';
import AllServices from './Pages/AllServices';
import About from './Pages/About';
import CategoryOfServices from './Pages/CategoryOfServices';
import NewService from './Pages/NewService';
import NewCategory from './Pages/NewCategory';
import AllProject from './Pages/AllProject';
import NewProject from './Pages/NewProject';
import CategoryOfProjects from './Pages/CategoryOfProjects';
import NewCategoryOfProjects from './Pages/NewCategoryOfProjects';
import AllProducts from './Pages/AllProducts';
import NewProduct from './Pages/NewProduct';
import ServicesContextProvider from './Context/ServicesContext';
import EditCategory from './Pages/EditCategory';
import LogIn from './Components/LogIn/LogIn';
import CategoryOfProducts from './Pages/CategoryOfProducts';
import NewCategoryOfProducts from './Pages/NewCategoryOfProducts';
import NewSubCategoryOfProducts from './Pages/NewSubCategoryOfProducts';
import SubCategoryOfProducts from './Pages/SubCategoryOfProducts';
import AllNews from './Pages/AllNews';
import NewPost from './Pages/NewPost';
import AboutContextProvider from './Context/AboutContext';
import EditAbout from './Components/About/EditAbout/EditAbout';
import EditService from './Pages/EditService';
import ProjectsContextProvider from './Context/ProjectsContext';
import EditProjectsCategory from './Pages/EditProjectsCategory';
import AuthContextProvider from './Context/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import EditProject from './Pages/EditProject';
import Careers from './Pages/Careers';
import CareersContextProvider, { CareersContext } from './Context/CareersContext';
import NewCareer from './Pages/NewCareer';
import EditCareer from './Pages/EditCareer';
import NewsContextProvider from './Context/NewsContext';
import EditPost from './Pages/EditPost';
import ProductsContextProvider from './Context/ProductsContext';
import EditProductsCategory from './Pages/EditProductsCategory';
import EditProductsSubCategory from './Pages/EditProductsSubCategory';
import BrandOfProducts from './Pages/BrandOfProducts';
import EditProductsBrand from './Pages/EditProductsBrand';
import NewBrandOfProducts from './Pages/NewBrandOfProjects';
import NewSubBrandOfProducts from './Pages/NewSubBrandOfProducts';
import SubBrandOfProducts from './Pages/SubBrandOfProducts';
import EditProductsSubBrand from './Pages/EditProductsSubBrand';
import EditProduct from './Pages/EditProduct';
import HomeContextProvider from './Context/HomeContext';
import EditBanner from './Pages/EditBanner';
import NewBanner from './Pages/NewBanner';
import AllPartners from './Pages/AllPartners';
import EditPartner from './Pages/EditPartner';
import NewPartner from './Pages/NewPartner';
import AllCounters from './Pages/AllCounters';
import EditCounter from './Pages/EditCounter';
import SocialLinks from './Pages/SocialLinks';
import EditSocialLinkForm from './Components/Home/SocialLinks/EditSocialLink/EditSocialLinkForm';
import EditSocialLinks from './Pages/EditSocialLinks';
import AllPages from './Pages/AllPages';
import EditPage from './Pages/EditPage';

function App() {
  const routers = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: "", element: <Navigate to="Home/AllBanners/en" /> },
        { path: 'Home/AllBanners/:lang', element: <ProtectedRoute><Banner /></ProtectedRoute> },
        { path: 'Home/EditBanner/:id', element: <ProtectedRoute><EditBanner /></ProtectedRoute> },
        { path: 'Home/NewBanner', element: <ProtectedRoute><NewBanner /></ProtectedRoute> },

        { path: 'Home/AllPartners/:lang', element: <ProtectedRoute><AllPartners /></ProtectedRoute> },
        { path: 'Home/EditPartner/:id', element: <ProtectedRoute><EditPartner /></ProtectedRoute> },
        { path: 'Home/NewPartner', element: <ProtectedRoute><NewPartner /></ProtectedRoute> },

        { path: 'Home/AllCounters/:lang', element: <ProtectedRoute><AllCounters /></ProtectedRoute> },
        { path: 'Home/EditCounter/:id', element: <ProtectedRoute><EditCounter /></ProtectedRoute> },

        { path: 'Home/AllSocialLinks', element: <ProtectedRoute><SocialLinks /></ProtectedRoute> },
        { path: 'Home/EditSocialLink/:id', element: <ProtectedRoute><EditSocialLinks /></ProtectedRoute> },

        { path: 'Services/AllServices/:lang', element: <ProtectedRoute><AllServices /></ProtectedRoute> },
        { path: 'Services/NewService', element: <ProtectedRoute><NewService /></ProtectedRoute> },
        { path: 'Services/EditService/:id', element: <ProtectedRoute><EditService /></ProtectedRoute> },

        { path: 'Services/Categories/:lang', element: <ProtectedRoute><CategoryOfServices /></ProtectedRoute> },
        { path: '/Services/NewCategory', element: <ProtectedRoute><NewCategory /></ProtectedRoute> },
        { path: 'Services/EditCategory/:id', element: <ProtectedRoute><EditCategory /></ProtectedRoute> },

        { path: 'About/AllAbout/:lang', element: <ProtectedRoute><About /> </ProtectedRoute> },
        { path: 'About/EditAbout/:id', element: <ProtectedRoute><EditAbout /> </ProtectedRoute> },

        { path: 'Projects/Categories/:lang', element: <ProtectedRoute><CategoryOfProjects /></ProtectedRoute> },
        { path: 'Projects/NewCategory', element: <ProtectedRoute><NewCategoryOfProjects /> </ProtectedRoute> },
        { path: 'Projects/EditCategory/:id', element: <ProtectedRoute><EditProjectsCategory /></ProtectedRoute> },

        { path: 'Projects/AllProjects/:lang', element: <ProtectedRoute><AllProject /></ProtectedRoute> },
        { path: 'Projects/NewProject', element: <ProtectedRoute><NewProject /></ProtectedRoute> },
        { path: 'Projects/EditProject/:id', element: <ProtectedRoute><EditProject /></ProtectedRoute> },

        { path: 'Careers/EditCareer/:id', element: <ProtectedRoute><EditCareer /></ProtectedRoute> },
        { path: 'Careers/:lang', element: <ProtectedRoute><Careers /></ProtectedRoute> },
        { path: 'Careers/NewCareer', element: <ProtectedRoute><NewCareer /></ProtectedRoute> },

        { path: 'News/AllNews/:lang', element: <ProtectedRoute><AllNews /></ProtectedRoute> },
        { path: 'News/NewPost', element: <ProtectedRoute><NewPost /></ProtectedRoute> },
        { path: 'News/EditPost/:id', element: <ProtectedRoute><EditPost /></ProtectedRoute> },

        { path: 'Products/AllProducts/:lang', element: <ProtectedRoute><AllProducts /></ProtectedRoute> },
        { path: 'Products/NewProduct', element: <ProtectedRoute><NewProduct /></ProtectedRoute> },
        { path: 'Products/EditProduct/:id', element: <ProtectedRoute><EditProduct /></ProtectedRoute> },

        { path: 'Products/Categories/:lang', element: <ProtectedRoute><CategoryOfProducts /></ProtectedRoute> },
        { path: 'Products/NewCategory', element: <ProtectedRoute><NewCategoryOfProducts /></ProtectedRoute> },
        { path: 'Products/EditCategory/:id', element: <ProtectedRoute><EditProductsCategory /></ProtectedRoute> },

        { path: 'Products/NewSubCategory', element: <ProtectedRoute><NewSubCategoryOfProducts /></ProtectedRoute> },
        { path: 'Products/SubCategories/:lang', element: <ProtectedRoute><SubCategoryOfProducts /></ProtectedRoute> },
        { path: 'Products/EditSubCategory/:id', element: <ProtectedRoute><EditProductsSubCategory /></ProtectedRoute> },

        { path: 'Products/Brands/:lang', element: <ProtectedRoute><BrandOfProducts /></ProtectedRoute> },
        { path: 'Products/EditBrand/:id', element: <ProtectedRoute><EditProductsBrand /></ProtectedRoute> },
        { path: 'Products/NewBrand', element: <ProtectedRoute><NewBrandOfProducts /></ProtectedRoute> },

        { path: 'Products/SubBrands/:lang', element: <ProtectedRoute><SubBrandOfProducts /></ProtectedRoute> },
        { path: 'Products/NewSubBrand', element: <ProtectedRoute><NewSubBrandOfProducts /></ProtectedRoute> },
        { path: 'Products/EditSubBrand/:id', element: <ProtectedRoute><EditProductsSubBrand /></ProtectedRoute> },

        { path: 'Pages/:lang', element: <ProtectedRoute><AllPages /></ProtectedRoute> },
        { path: 'Pages/EditPage/:id', element: <ProtectedRoute><EditPage /></ProtectedRoute> },
      ],
    },
    { path: 'Login', element: <LogIn /> },
  ]);

  return (
    <div className="App">
      <ServicesContextProvider>
        <ProjectsContextProvider>
          <ProductsContextProvider>
            <AboutContextProvider>
              <NewsContextProvider>
                <CareersContextProvider>
                  <AuthContextProvider>
                    <HomeContextProvider>
                      <RouterProvider router={routers}></RouterProvider>
                    </HomeContextProvider>
                  </AuthContextProvider>
                </CareersContextProvider>
              </NewsContextProvider>
            </AboutContextProvider>
          </ProductsContextProvider>
        </ProjectsContextProvider>
      </ServicesContextProvider>
    </div >
  );
}

export default App;
